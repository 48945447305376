import React, { useState, useEffect } from 'react';

function ResendButton({ onResend, disabled }) {
  const [countdown, setCountdown] = useState(0);
  const COOLDOWN_TIME = 60; // 60 seconds cooldown

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleResend = () => {
    onResend();
    setCountdown(COOLDOWN_TIME);
  };

  return (
    <button
      onClick={handleResend}
      disabled={countdown > 0 || disabled}
      className="border-2 border-white p-4 text-xl uppercase hover:bg-white hover:text-black transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {countdown > 0 ? `RESEND (${countdown}s)` : 'RESEND'}
    </button>
  );
}

export default ResendButton; 