import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function NotFound({ message = 'Page Not Found' }) {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black text-white font-mono flex items-center justify-center">
      <motion.div 
        className="text-center space-y-8 p-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <h1 className="text-6xl font-bold tracking-tighter uppercase">
          404
        </h1>
        <p className="text-4xl font-bold tracking-tighter uppercase text-gray-500">
          {message}
        </p>
        <div className="space-y-4 text-gray-400">
          <p>The page you're looking for doesn't exist or has been moved.</p>
        </div>
        <button
          onClick={() => navigate('/')}
          className="border-2 border-white px-8 py-4 text-xl uppercase hover:bg-white hover:text-black transition-colors"
        >
          Return to Message Board
        </button>
      </motion.div>
    </div>
  );
}

export default NotFound; 