import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../config';
import { validatePhoneNumber, formatPhoneNumber, formatPhoneNumberForFirebase } from '../utils/phoneUtils';
import { createOrUpdateUser, getUserStatus, addMessageToUser } from '../utils/userManager';
import { filterContent, isUserBanned } from '../utils/contentFilter';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config';
import { initializeRecaptcha, cleanupRecaptcha } from '../utils/recaptchaUtils';
import FormStep from '../components/FormStep';
import Modal from '../components/Modal';
import { logPageView, logUserEngagement } from '../config';
import ResendButton from '../components/ResendButton';
import { doc, collection, addDoc, serverTimestamp, query, getDocs, where, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config';

function SubmissionForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    displayName: '',
    message: '',
    marketingOptIn: false,
    displayAnonymously: false
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    type: 'info',
    actions: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserBanned()) {
      navigate('/banned');
    }
  }, [navigate]);

  useEffect(() => {
    return () => {
      cleanupRecaptcha();
    };
  }, []);

  useEffect(() => {
    logPageView('submission_form');
    
    // Get referrer information
    const referrer = document.referrer;
    logUserEngagement('form_view', {
      referrer: referrer || 'direct',
      utm_source: new URLSearchParams(window.location.search).get('utm_source')
    });
  }, []);

  useEffect(() => {
    if (currentStep === 3) {
      setFormData(prev => ({
        ...prev,
        displayName: prev.displayName || `${prev.firstName} ${prev.lastName}`.trim()
      }));
    }
  }, [currentStep]);

  const handleSendCode = async () => {
    if (!validatePhoneNumber(formData.phone)) {
      setModalConfig({
        isOpen: true,
        title: 'Invalid Phone Number',
        message: 'Please enter a valid US phone number',
        type: 'error'
      });
      return;
    }

    setIsVerifying(true);
    try {
      // Clean up any existing reCAPTCHA instances first
      await cleanupRecaptcha();
      
      const formattedPhone = formatPhoneNumberForFirebase(formData.phone);
      
      // Check if user is rate limited
      const userStatus = await getUserStatus(formattedPhone);
      if (userStatus?.lastAttempt) {
        const timeSinceLastAttempt = Date.now() - userStatus.lastAttempt;
        if (timeSinceLastAttempt < 60000) { // 1 minute cooldown
          throw new Error('Please wait a minute before trying again');
        }
      }

      // Initialize reCAPTCHA
      const verifier = await initializeRecaptcha();
      
      // Add delay between attempts
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Attempt phone sign in
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhone, verifier);
      setVerificationId(confirmationResult);
      
      setModalConfig({
        isOpen: true,
        title: 'Code Sent',
        message: 'A verification code has been sent to your phone',
        type: 'success'
      });
    } catch (error) {
      console.error('Error details:', error);
      
      let errorMessage = 'Failed to send verification code. Please try again later.';
      
      if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Too many attempts. Please try again in a few minutes.';
      } else if (error.code === 'auth/invalid-phone-number') {
        errorMessage = 'Please enter a valid phone number.';
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      setModalConfig({
        isOpen: true,
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });

      // Always cleanup on error
      await cleanupRecaptcha();
    } finally {
      setIsVerifying(false);
    }
  };

  const handleVerifyCode = async () => {
    try {
      await verificationId.confirm(verificationCode);
      setIsVerified(true);
      logEvent(analytics, 'phone_verified');
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Invalid verification code. Please try again.');
    }
  };

  const handlePhoneChange = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, phone: formatted });
  };

  const handleMessageChange = (e) => {
    const message = e.target.value;
    setCharCount(message.length);
    setFormData({ ...formData, message: message });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const user = auth.currentUser;
      if (!user) {
        setModalConfig({
          isOpen: true,
          title: 'Error',
          message: 'You must be logged in to submit a message',
          type: 'error'
        });
        return;
      }

      // First check if user exists by phone number
      const userQuery = query(collection(db, 'users'), where('phone', '==', user.phoneNumber));
      const querySnapshot = await getDocs(userQuery);
      
      let userId;
      
      if (querySnapshot.empty) {
        // Create new user with auth.uid as document ID
        const newUser = {
          phone: user.phoneNumber,
          firstName: formData.firstName,
          lastName: formData.lastName,
          displayName: formData.displayName,
          email: formData.email,
          createdAt: new Date(),
          strikes: 0,
          banned: false,
          lastMessageAt: null
        };
        
        await setDoc(doc(db, 'users', user.uid), newUser);
        userId = user.uid;
      } else {
        // Use existing user's ID
        userId = querySnapshot.docs[0].id;
        // Update user info
        await updateDoc(doc(db, 'users', userId), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          displayName: formData.displayName,
          email: formData.email
        });
      }

      const contentCheck = await filterContent(formData.message);
      if (!contentCheck.isValid) {
        setModalConfig({
          isOpen: true,
          title: 'Content Warning',
          message: contentCheck.reason,
          type: 'error'
        });
        return;
      }

      // Add message using the correct userId
      await addMessageToUser(userId, {
        content: contentCheck.sanitizedText || formData.message,
        displayName: formData.displayAnonymously ? 'Anonymous' : formData.displayName,
        displayAnonymously: formData.displayAnonymously,
        eventId: null
      });

      navigate('/success');
    } catch (error) {
      console.error('Error:', error);
      setModalConfig({
        isOpen: true,
        title: 'Error',
        message: 'Failed to submit message. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    if (currentStep === 1) {
      if (!formData.firstName.trim() || !formData.lastName.trim()) {
        setModalConfig({
          isOpen: true,
          title: 'Missing Information',
          message: 'Please enter your first and last name',
          type: 'error'
        });
        return;
      }
      if (!formData.email || !formData.phone) {
        setModalConfig({
          isOpen: true,
          title: 'Missing Information',
          message: 'Please fill in all required fields',
          type: 'error'
        });
        return;
      }
    }
    setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => setCurrentStep(prev => prev - 1);

  const handleResendCode = async () => {
    setVerificationCode('');
    await handleSendCode();
  };

  return (
    <div className="min-h-screen bg-black text-white font-mono flex items-center justify-center">
      {/* Place reCAPTCHA container at the bottom */}
      <div 
        id="recaptcha-container" 
        className="fixed bottom-0 left-0 opacity-0 pointer-events-none"
      ></div>
      
      {/* Progress Indicator */}
      <div className="fixed top-0 left-0 right-0 flex">
        {[1, 2, 3].map((step) => (
          <div 
            key={step}
            className={`flex-1 h-2 ${currentStep >= step ? 'bg-white' : 'bg-zinc-800'}`}
          />
        ))}
      </div>

      <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 lg:p-8">
        {/* Step 1: Initial Info */}
        <FormStep isActive={currentStep === 1}>
          <div className="space-y-8">
            <h1 className="text-6xl font-bold tracking-tighter uppercase">BLANK</h1>
            
            <p className="text-sm text-gray-400 leading-relaxed">
              Share your message with the BLANK community. Your message will be displayed on our live message board. 
              All submissions are moderated for content.
            </p>
            
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  placeholder="FIRST NAME"
                  className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                  required
                />
                <input
                  type="text"
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  placeholder="LAST NAME"
                  className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                  required
                />
              </div>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                placeholder="EMAIL"
                className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                required
              />
              <input
                type="tel"
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="PHONE"
                className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                required
              />
              <button
                onClick={nextStep}
                className="w-full border-2 border-white p-4 text-xl hover:bg-white hover:text-black transition-colors uppercase"
              >
                CONTINUE →
              </button>
            </div>
          </div>
        </FormStep>

        {/* Step 2: Verification */}
        <FormStep isActive={currentStep === 2}>
          <div className="space-y-8">
            <div className="flex justify-between items-center">
              <button
                onClick={prevStep}
                className="text-sm text-gray-400 hover:text-white"
              >
                ← Back
              </button>
              <h2 className="text-4xl font-bold uppercase tracking-tight">VERIFY YOUR PHONE</h2>
            </div>
            <div className="space-y-4">
              {!verificationId ? (
                <div className="space-y-4">
                  <button
                    onClick={handleSendCode}
                    disabled={isVerifying}
                    className="w-full border-2 border-white p-4 text-xl uppercase hover:bg-white hover:text-black transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isVerifying ? 'SENDING...' : 'GET VERIFICATION CODE'}
                  </button>
                </div>
              ) : !isVerified ? (
                <>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="ENTER CODE"
                    className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                  />
                  <div className="flex gap-4">
                    <button
                      onClick={handleVerifyCode}
                      className="flex-1 border-2 border-white p-4 text-xl uppercase hover:bg-white hover:text-black transition-colors"
                    >
                      VERIFY
                    </button>
                    <ResendButton 
                      onResend={handleResendCode}
                      disabled={isVerifying}
                    />
                  </div>
                </>
              ) : (
                <button
                  onClick={nextStep}
                  className="w-full border-2 border-white p-4 text-xl uppercase hover:bg-white hover:text-black transition-colors"
                >
                  CONTINUE →
                </button>
              )}
            </div>
          </div>
        </FormStep>

        {/* Step 3: Message */}
        <FormStep isActive={currentStep === 3}>
          <div className="space-y-8">
            <div className="flex justify-between items-center">
              <button
                onClick={prevStep}
                className="text-sm text-gray-400 hover:text-white"
              >
                ← Back
              </button>
              <h2 className="text-4xl font-bold uppercase tracking-tight">YOUR MESSAGE</h2>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                value={formData.displayName}
                onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                placeholder="DISPLAY NAME"
                className="w-full bg-transparent border-2 border-white p-4 text-xl uppercase"
                required
              />
              <textarea
                value={formData.message}
                onChange={handleMessageChange}
                rows={4}
                maxLength={500}
                placeholder="TYPE YOUR MESSAGE..."
                className="w-full bg-transparent border-2 border-white p-4 text-xl"
                required
              />
              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={formData.displayAnonymously}
                    onChange={(e) => setFormData({ ...formData, displayAnonymously: e.target.checked })}
                    className="w-5 h-5"
                  />
                  <span>Post Anonymously</span>
                </label>
                <span className="ml-auto">{charCount}/500</span>
              </div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={formData.marketingOptIn}
                  onChange={(e) => setFormData({ ...formData, marketingOptIn: e.target.checked })}
                  className="w-5 h-5"
                  required
                />
                <span className="uppercase text-sm">I AGREE TO RECEIVE UPDATES</span>
              </label>
              <div className="text-sm text-gray-400">
                By submitting, you agree to our <Link to="/policy" className="underline hover:text-white">content policy</Link>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full border-2 border-white p-4 text-xl uppercase hover:bg-white hover:text-black transition-colors"
              >
                {isLoading ? 'SENDING...' : 'SUBMIT'}
              </button>
            </form>
          </div>
        </FormStep>
      </div>

      <Modal
        isOpen={modalConfig.isOpen}
        onClose={() => setModalConfig({ ...modalConfig, isOpen: false })}
        title={modalConfig.title}
        message={modalConfig.message}
        type={modalConfig.type}
        actions={modalConfig.actions}
      />
    </div>
  );
}

export default SubmissionForm; 