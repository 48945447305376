import React, { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { query, orderBy, onSnapshot, limit, collectionGroup, where } from 'firebase/firestore';
import { createMessage } from '../utils/messageService';
import { auth, db } from '../config';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import { useFullScreen } from '../hooks/useFullScreen';
import { isUserBanned } from '../utils/contentFilter';
import Marquee from '../components/Marquee';
import FullScreenButton from '../components/FullScreenButton';
import QRCodeDisplay from '../components/QRCodeDisplay';
import { logPageView, logUserEngagement } from '../config';


function MessageBoard({ eventId, isEnabled }) {
  const [messages, setMessages] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const DISPLAY_TIME = 10000; // 10 seconds in milliseconds
  const navigate = useNavigate();
  const { isFullScreen, enterFullScreen, exitFullScreen } = useFullScreen();
  const isBanned = isUserBanned();
  const MAX_DISPLAYED_MESSAGES = 20;

  useEffect(() => {
    logPageView('message_board');
    
    // Track user engagement time
    const startTime = Date.now();
    return () => {
      const engagementTime = (Date.now() - startTime) / 1000; // in seconds
      logUserEngagement('user_engagement', {
        engagement_time: engagementTime,
        page: 'message_board'
      });
    };
  }, []);

  useEffect(() => {
    const q = query(
      collectionGroup(db, 'messages'),
      where('isHidden', '==', false),
      eventId ? where('eventId', '==', eventId) : where('eventId', '==', null),
      orderBy('timestamp', 'desc')
    );
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(newMessages);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [eventId]);

  const processMessageQueue = useCallback(() => {
    if (isProcessingQueue || messageQueue.length === 0) return;
    
    setIsProcessingQueue(true);
    const messageToAdd = messageQueue[0];
    
    const messageWithDisplayTime = {
      ...messageToAdd,
      displayStartTime: Date.now()
    };
    
    if (!messages.some(m => m.id === messageToAdd.id)) {
      setMessages(prev => {
        const newMessages = [messageWithDisplayTime, ...prev];
        return newMessages.sort((a, b) => b.timestamp - a.timestamp).slice(0, MAX_DISPLAYED_MESSAGES);
      });
    }
    
    setMessageQueue(prev => prev.slice(1));
    setIsProcessingQueue(false);
  }, [isProcessingQueue, messageQueue, messages, MAX_DISPLAYED_MESSAGES]);

  useEffect(() => {
    const queueInterval = setInterval(processMessageQueue, 100);
    return () => clearInterval(queueInterval);
  }, [processMessageQueue]);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const messageVariants = {
    hidden: { 
      opacity: 0,
      x: -20,
      scale: 0.8
    },
    show: { 
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    exit: {
      opacity: 0,
      x: 20,
      scale: 0.8,
      transition: {
        duration: 0.3
      }
    }
  };

  const handleMessageClick = (messageId) => {
    logUserEngagement('message_click', {
      message_id: messageId
    });
  };

  const handleSubmitMessage = async (messageContent) => {
    if (!messageContent.trim() || (eventId && !isEnabled)) return;

    try {
      const user = auth.currentUser;
      if (!user) return;

      await createMessage(user.uid, {
        content: messageContent.trim(),
        displayName: user.displayName || 'Anonymous',
        displayAnonymously: false,
        eventId: eventId
      });
    } catch (error) {
      console.error('Error submitting message:', error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white font-mono pt-16">
      <FullScreenButton 
        isFullScreen={isFullScreen} 
        toggleFullScreen={isFullScreen ? exitFullScreen : enterFullScreen} 
      />
      <QRCodeDisplay url={window.location.origin + '/message'} />
      <motion.div 
        className="fixed top-0 left-0 right-0 bg-black z-40"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
      >
        <Marquee url={window.location.origin + '/message'} />
      </motion.div>

      <div className="container mx-auto max-w-2xl px-4 pt-8 pb-20 message-container">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <AnimatePresence mode="popLayout">
            {messages
              .sort((a, b) => b.timestamp - a.timestamp)
              .map((message, index) => (
                message && message.id ? (
                  <motion.div
                    key={message.id}
                    variants={messageVariants}
                    initial="hidden"
                    animate="show"
                    exit="exit"
                    layout
                    className="mb-4"
                  >
                    <Message message={message} onClick={() => handleMessageClick(message.id)} />
                  </motion.div>
                ) : null
              ))}
          </AnimatePresence>
        </motion.div>

        {isLoading ? (
          <motion.div 
            className="text-center space-y-8 mt-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <p className="text-4xl font-bold tracking-tighter uppercase animate-pulse">
              Loading Messages...
            </p>
            <p className="text-sm opacity-50">
              If this takes longer than expected, the message index might be building.
            </p>
          </motion.div>
        ) : messages.length === 0 && (
          <motion.div 
            className="text-center space-y-8 mt-20"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <p className="text-6xl font-bold tracking-tighter uppercase animate-pulse">
              NO MESSAGES YET
            </p>
            {!isBanned && (
              <button
                onClick={() => navigate('/message')}
                className="border-2 border-white px-8 py-4 text-xl uppercase hover:bg-white hover:text-black transition-colors"
              >
                BE THE FIRST TO SHARE
              </button>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default MessageBoard;