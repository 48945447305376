import React, { useState } from 'react';
import { incrementUserStrikes } from './userManager';
import profanityFilter from './profanityList';
import { logContentViolation } from '../config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

// Content validation constants
const MAX_MESSAGE_LENGTH = 500;
const MAX_CAPS_RATIO = 0.7;
const MIN_LENGTH = 2;
const MAX_REPEATED_CHARS = 4;
const SPAM_PATTERNS = [
  /([A-Za-z0-9])\1{4,}/,  // Repeated characters
  /(.[.,!?]){5,}/,        // Excessive punctuation
  /\b(\w+\s+\1\s*){3,}/   // Repeated words
];

export const filterContent = async (content, userId) => {
  try {
    // Basic validation
    if (!content || typeof content !== 'string') {
      return createResponse(false, 'Invalid message format');
    }

    const trimmedContent = content.trim();
    
    // Length checks
    if (trimmedContent.length < MIN_LENGTH) {
      return createResponse(false, 'Message is too short');
    }
    
    if (trimmedContent.length > MAX_MESSAGE_LENGTH) {
      return createResponse(false, `Message exceeds maximum length of ${MAX_MESSAGE_LENGTH} characters`);
    }

    // Check for banned content
    const bannedWordMatch = profanityFilter.findBannedWord(trimmedContent);
    if (bannedWordMatch) {
      await handleContentViolation(userId, 'hate_speech', bannedWordMatch);
      return createResponse(false, 'Message contains prohibited content');
    }

    // Spam detection
    if (isSpam(trimmedContent)) {
      await handleContentViolation(userId, 'spam', trimmedContent);
      return createResponse(false, 'Message detected as spam');
    }

    // Personal information detection
    if (containsPersonalInfo(trimmedContent)) {
      await handleContentViolation(userId, 'personal_info', trimmedContent);
      return createResponse(false, 'Message contains personal information');
    }

    // Sanitize allowed words
    const sanitizedContent = profanityFilter.sanitize(trimmedContent);

    // Final validation
    if (!isValidContent(sanitizedContent)) {
      return createResponse(false, 'Message contains invalid content');
    }

    return createResponse(true, null, sanitizedContent);
  } catch (error) {
    console.error('Content filter error:', error);
    return createResponse(false, 'Error processing message');
  }
};

const isSpam = (content) => {
  // Check caps ratio
  const capsRatio = (content.match(/[A-Z]/g) || []).length / content.length;
  if (capsRatio > MAX_CAPS_RATIO && content.length > 10) {
    return true;
  }

  // Check spam patterns
  return SPAM_PATTERNS.some(pattern => pattern.test(content));
};

const containsPersonalInfo = (content) => {
  const patterns = {
    email: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g,
    phone: /(\+\d{1,3}[-.]?)?\(?\d{3}\)?[-.]?\d{3}[-.]?\d{4}/g,
    ssn: /\b\d{3}[-]?\d{2}[-]?\d{4}\b/g,
    creditCard: /\b\d{4}[-]?\d{4}[-]?\d{4}[-]?\d{4}\b/g
  };

  return Object.values(patterns).some(pattern => pattern.test(content));
};

const isValidContent = (content) => {
  // Additional custom validation rules
  const invalidPatterns = [
    /\b(http|www\.)\S+/i,  // No URLs
    /\$\d+/,               // No prices
    /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i  // No emails
  ];

  return !invalidPatterns.some(pattern => pattern.test(content));
};

const handleContentViolation = async (userId, type, content) => {
  if (!userId) return;

  try {
    const result = await incrementUserStrikes(userId);
    
    // Log violation for monitoring
    await logContentViolation({
      userId,
      type,
      content,
      timestamp: new Date(),
      strikeCount: result?.strikes || 0,
      banned: result?.banned || false
    });

    // Log user activity for admin portal
    const activityRef = collection(db, `users/${userId}/activities`);
    await addDoc(activityRef, {
      type: 'content_violation',
      details: {
        violationType: type,
        strikeCount: result?.strikes || 0,
        banned: result?.banned || false
      },
      timestamp: serverTimestamp()
    });

    if (result?.banned) {
      localStorage.setItem('userBanned', 'true');
      // Log ban activity
      await addDoc(activityRef, {
        type: 'ban',
        details: {
          reason: 'Multiple content violations',
          permanent: true
        },
        timestamp: serverTimestamp()
      });
    }
  } catch (error) {
    console.error('Error handling content violation:', error);
  }
};

const createResponse = (isValid, reason = null, sanitizedText = null) => ({
  isValid,
  reason,
  sanitizedText
});

export const isUserBanned = () => {
  return localStorage.getItem('userBanned') === 'true';
};

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const MessageComponent = () => {
  const [message, setMessage] = useState('');
  const [filteredMessage, setFilteredMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = filterContent(message);
    setFilteredMessage(result);
  };

  return (
    <div>
      <h2>Send a Message</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="4"
          cols="50"
        />
        <br />
        <button type="submit">Send Message</button>
      </form>

      {filteredMessage && (
        <div>
          <h3>Message Result:</h3>
          <p><strong>Sanitized Message:</strong> {filteredMessage.sanitizedText}</p>
          {filteredMessage.reason && <p><em>{filteredMessage.reason}</em></p>}
        </div>
      )}
    </div>
  );
};

export default MessageComponent;

