import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config';

export const createOrUpdateUser = async (userData) => {
  const { phone, firstName, lastName, email, displayName } = userData;
  
  if (!firstName || !lastName || !email) {
    throw new Error('First name, last name, and email are required');
  }

  const userQuery = query(collection(db, 'users'), where('phone', '==', phone));
  const querySnapshot = await getDocs(userQuery);

  const userDisplayName = displayName || `${firstName} ${lastName}`;

  if (querySnapshot.empty) {
    const newUser = {
      phone,
      firstName,
      lastName,
      displayName: userDisplayName,
      email,
      createdAt: new Date(),
      strikes: 0,
      banned: false,
      lastMessageAt: null
    };
    
    const userRef = doc(collection(db, 'users'));
    await setDoc(userRef, newUser);
    return { id: userRef.id, ...newUser };
  }

  const existingUser = querySnapshot.docs[0];
  await updateDoc(existingUser.ref, { 
    firstName, 
    lastName,
    displayName: userDisplayName,
    email 
  });

  return { 
    id: existingUser.id, 
    ...existingUser.data(), 
    firstName, 
    lastName,
    displayName: userDisplayName,
    email 
  };
};

export const addMessageToUser = async (userId, messageData) => {
  const userRef = doc(db, 'users', userId);
  const messagesRef = collection(userRef, 'messages');
  
  const messageDoc = {
    content: messageData.content,
    timestamp: serverTimestamp(),
    userId: userId,
    displayName: messageData.displayName,
    displayAnonymously: messageData.displayAnonymously,
    eventId: messageData.eventId || null,
    isHidden: false,
    isModerated: false
  };
  
  return await addDoc(messagesRef, messageDoc);
};

export const incrementUserStrikes = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (!userDoc.exists()) return null;
  
  const currentStrikes = userDoc.data().strikes || 0;
  const newStrikes = currentStrikes + 1;
  const banned = newStrikes >= 3;
  
  await updateDoc(userRef, {
    strikes: newStrikes,
    banned,
    lastStrikeAt: new Date()
  });

  return { strikes: newStrikes, banned };
};

export const getUserStatus = async (phone) => {
  try {
    const statusDoc = await getDoc(doc(db, 'userStatus', phone));
    return statusDoc.exists() ? statusDoc.data() : null;
  } catch (error) {
    console.error('Error getting user status:', error);
    return null;
  }
}; 