import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect to home after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-black text-white font-mono flex items-center justify-center">
      <div className="text-center space-y-8 p-4">
        <h1 className="text-6xl font-bold tracking-tighter uppercase animate-fade-in">
          THANK YOU
        </h1>
        <p className="text-xl animate-fade-in">
          Your message has been submitted successfully
        </p>
        <p className="text-gray-400 animate-fade-in">
          Redirecting you in 5 seconds...
        </p>
        <button
          onClick={() => navigate('/')}
          className="border-2 border-white px-8 py-4 text-xl uppercase hover:bg-white hover:text-black transition-colors animate-fade-in"
        >
          RETURN HOME
        </button>
      </div>
    </div>
  );
}

export default SuccessPage; 