import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Marquee({ url }) {
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString('en-US', { 
    month: 'long', 
    day: 'numeric', 
    year: 'numeric' 
  });
  
  const text = `BLANK LOCATIONS LIVE MESSAGE BOARD • ${currentDate} • CLICK HERE TO SHARE YOUR THOUGHTS • `;
  const repeatedText = text.repeat(8);

  return (
    <div 
      className="overflow-hidden whitespace-nowrap border-y-2 border-white bg-black py-3 cursor-pointer relative"
      onClick={() => navigate('/message')}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black pointer-events-none z-10" />
      <motion.div
        animate={{
          x: ["0%", "-50%"]
        }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 100,
            ease: "linear",
          },
        }}
        className="inline-block text-2xl tracking-widest uppercase font-mono"
      >
        {repeatedText}
      </motion.div>
    </div>
  );
}

export default Marquee; 