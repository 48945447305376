import React from 'react';
import { motion } from 'framer-motion';

const messageVariants = {
  hidden: { 
    opacity: 0,
    x: -20,
    scale: 0.8
  },
  show: { 
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15
    }
  },
  exit: {
    opacity: 0,
    x: 20,
    scale: 0.8,
    transition: {
      duration: 0.3
    }
  }
};

const Message = ({ message }) => {
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp?.toDate?.() || new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const displayName = message.displayAnonymously ? 'Anonymous' : message.displayName;

  return (
    <motion.div 
      className="border-2 border-white p-4 space-y-2"
      variants={messageVariants}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">{displayName}</h3>
        <span className="text-sm text-gray-400">
          {formatTimestamp(message.timestamp)}
        </span>
      </div>
      <p>{message.content}</p>
    </motion.div>
  );
};

export default Message; 