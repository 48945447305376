import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// Verify environment variables are loaded
const requiredEnvVars = [
  'REACT_APP_FIREBASE_API_KEY',
  'REACT_APP_FIREBASE_AUTH_DOMAIN',
  'REACT_APP_FIREBASE_PROJECT_ID',
  'REACT_APP_FIREBASE_STORAGE_BUCKET',
  'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
  'REACT_APP_FIREBASE_APP_ID'
];

const missingVars = requiredEnvVars.filter(varName => !process.env[varName]);
if (missingVars.length > 0) {
  throw new Error(`Missing required environment variables: ${missingVars.join(', ')}`);
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

let app, db, analytics, auth;

try {
  app = initializeApp(firebaseConfig);
  db = getFirestore(app);
  auth = getAuth(app);
  analytics = getAnalytics(app);
  auth.useDeviceLanguage();
} catch (error) {
  console.error('Firebase initialization error:', error);
  console.error('Environment:', process.env.NODE_ENV);
  console.error('Config:', {
    ...firebaseConfig,
    apiKey: firebaseConfig.apiKey ? '[PRESENT]' : '[MISSING]'
  });
  throw error;
}

// Analytics helper functions
export const logPageView = (page) => {
  logEvent(analytics, 'page_view', { page_name: page });
};

export const logUserEngagement = (event, params) => {
  logEvent(analytics, event, params);
};

export const logContentViolation = async (violationData) => {
  const { userId, type, content, timestamp, strikeCount, banned } = violationData;
  
  // Log to Firebase Analytics
  logEvent(analytics, 'content_violation', {
    violation_type: type,
    user_id: userId,
    strike_count: strikeCount,
    banned: banned,
    timestamp: timestamp.getTime()
  });

  // Log to Firestore for detailed tracking
  try {
    const violationsRef = collection(db, 'contentViolations');
    await addDoc(violationsRef, {
      userId,
      type,
      content: type === 'spam' ? null : content, // Don't store spam content
      timestamp,
      strikeCount,
      banned
    });
  } catch (error) {
    console.error('Error logging content violation:', error);
  }
};

export { db, auth, analytics };