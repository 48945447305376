import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function FullScreenButton({ isFullScreen, toggleFullScreen }) {
  const [isVisible, setIsVisible] = useState(false);
  let timeout;

  useEffect(() => {
    const handleMouseMove = () => {
      setIsVisible(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsVisible(false), 2000);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          className="fixed top-4 right-4 text-white opacity-50 hover:opacity-100 z-50 text-4xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? '⤓' : '⤢'}
        </motion.button>
      )}
    </AnimatePresence>
  );
}

export default FullScreenButton; 