import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MessageBoard from './pages/MessageBoard';
import EventMessageBoard from './components/EventMessageBoard';
import SubmissionForm from './pages/SubmissionForm';
import SuccessPage from './pages/SuccessPage';
import BannedPage from './pages/BannedPage';
import PolicyPage from './pages/PolicyPage';
import { useEffect } from 'react';
import { logUserEngagement } from './config';

function App() {
  useEffect(() => {
    // Track page load performance
    window.addEventListener('load', () => {
      const navigation = performance.getEntriesByType('navigation')[0];
      logUserEngagement('page_performance', {
        load_time: navigation.loadEventEnd - navigation.startTime,
        dom_interactive: navigation.domInteractive - navigation.startTime,
        first_contentful_paint: performance.getEntriesByType('paint')[0]?.startTime
      });
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MessageBoard />} />
        <Route path="/events/:eventId/messages" element={<EventMessageBoard />} />
        <Route path="/message" element={<SubmissionForm />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/banned" element={<BannedPage />} />
        <Route path="/policy" element={<PolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
