import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function PolicyPage() {
  return (
    <div className="min-h-screen bg-black text-white font-mono p-4 sm:p-8">
      <motion.div 
        className="max-w-3xl mx-auto space-y-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <h1 className="text-4xl font-bold tracking-tighter uppercase">Terms & Policies</h1>
        
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">Content Policy</h2>
          <p>By using this message board, you agree to our content guidelines. Messages must not contain:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Hate speech, harassment, or discriminatory content</li>
            <li>Explicit, violent, or inappropriate material</li>
            <li>Personal information or private data</li>
            <li>Spam or commercial solicitation</li>
            <li>Content that violates any applicable laws</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-bold">Data Collection & Analytics</h2>
          <p>We collect and process the following data to improve our service:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Usage data (page views, engagement time, interactions)</li>
            <li>Message metrics (length, submission time, anonymous status)</li>
            <li>General location data (city/country level only)</li>
            <li>Device information (browser type, operating system)</li>
            <li>Performance metrics (page load times, technical data)</li>
          </ul>
          <p className="mt-2 text-sm text-gray-400">This data is processed using Firebase Analytics and is used solely for improving user experience and service functionality.</p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-bold">Privacy & Security</h2>
          <p>We prioritize your privacy and security:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Phone numbers are used for verification only</li>
            <li>Location data is collected at city-level only, never precise</li>
            <li>Analytics data is anonymized and aggregated</li>
            <li>We do not sell or share personal data with third parties</li>
            <li>Data is stored securely using Firebase infrastructure</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-bold">Disclaimer</h2>
          <p>BLANK LOCATIONS is not responsible for user-generated content. We reserve the right to:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Remove any content without notice</li>
            <li>Ban users who violate our policies</li>
            <li>Modify or discontinue the service at any time</li>
            <li>Update these policies without prior notice</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-bold">Liability</h2>
          <p>THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. BLANK LOCATIONS SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM THE USE OF THIS SERVICE.</p>
        </section>

        <Link 
          to="/"
          className="inline-block border-2 border-white px-8 py-4 text-xl uppercase hover:bg-white hover:text-black transition-colors mt-8"
        >
          Return to Message Board
        </Link>
      </motion.div>
    </div>
  );
}

export default PolicyPage; 