// Banned word categories with severity levels
const PROFANITY_LISTS = {
  HATE_SPEECH: {
    severity: 3,
    words: [
      'nigger', 'nigga', 'fag', 'faggot', 'retard', 'kike', 'spic', 'chink',
      'tranny', 'wetback', 'beaner', 'towelhead'
    ]
  },
  EXPLICIT: {
    severity: 2,
    words: [
      'fuck', 'fucking', 'fucker', 'shit', 'bitch', 'asshole', 'bastard',
      'dick', 'piss', 'damn', 'crap', 'slut', 'prick', 'whore', 'suck',
      'douche', 'cock', 'pussy', 'twat', 'wanker', 'arse', 'bollocks'
    ]
  },
  MILD: {
    severity: 1,
    words: [
      'stupid', 'idiot', 'dumb', 'moron', 'loser', 'fat', 'ugly'
    ]
  }
};

class ProfanityFilter {
  constructor() {
    this.cache = new Map();
    this.initializeWordSets();
  }

  initializeWordSets() {
    this.bannedWords = new Set(PROFANITY_LISTS.HATE_SPEECH.words);
    this.explicitWords = new Set(PROFANITY_LISTS.EXPLICIT.words);
    this.mildWords = new Set(PROFANITY_LISTS.MILD.words);
  }

  findBannedWord(text) {
    if (this.cache.has(text)) {
      return this.cache.get(text);
    }

    const words = text.toLowerCase().split(/\s+/);
    const found = words.find(word => this.bannedWords.has(word));
    
    this.cache.set(text, found || null);
    return found;
  }

  sanitize(text) {
    if (!text) return text;

    let sanitized = text;
    const words = text.split(/\b/);

    words.forEach(word => {
      const lower = word.toLowerCase();
      if (this.explicitWords.has(lower)) {
        sanitized = sanitized.replace(word, '*'.repeat(word.length));
      } else if (this.mildWords.has(lower)) {
        const firstChar = word.charAt(0);
        sanitized = sanitized.replace(word, firstChar + '*'.repeat(word.length - 1));
      }
    });

    return sanitized;
  }

  getSeverity(word) {
    if (this.bannedWords.has(word)) return PROFANITY_LISTS.HATE_SPEECH.severity;
    if (this.explicitWords.has(word)) return PROFANITY_LISTS.EXPLICIT.severity;
    if (this.mildWords.has(word)) return PROFANITY_LISTS.MILD.severity;
    return 0;
  }

  clearCache() {
    this.cache.clear();
  }
}

const profanityFilter = new ProfanityFilter();
export default profanityFilter; 