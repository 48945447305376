import React from 'react';
import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';

function QRCodeDisplay({ url }) {
  return (
    <motion.div 
      className="fixed bottom-8 right-8 bg-black border-2 border-white p-4 hidden md:block"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
    >
      <QRCodeSVG 
        value={url}
        size={200}
        level="H"
        className="w-48 h-48"
        bgColor="#000000"
        fgColor="#FFFFFF"
      />
      <p className="text-white text-center mt-2 text-sm uppercase tracking-wide">
        Scan to Submit
      </p>
    </motion.div>
  );
}

export default QRCodeDisplay; 