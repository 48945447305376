import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const validatePhoneNumber = (phone) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US');
    return phoneNumber?.isValid() && phoneNumber.country === 'US';
  } catch (error) {
    return false;
  }
};

export const formatPhoneNumberForFirebase = (phone) => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US');
  return phoneNumber ? phoneNumber.format('E.164') : null;
}; 