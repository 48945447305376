import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../config';

let recaptchaVerifier = null;

export const initializeRecaptcha = async () => {
  try {
    await cleanupRecaptcha();

    // Create container if it doesn't exist
    let container = document.getElementById('recaptcha-container');
    if (!container) {
      container = document.createElement('div');
      container.id = 'recaptcha-container';
      container.className = 'invisible-recaptcha';
      document.body.appendChild(container);
    }

    recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log('reCAPTCHA verified');
      },
      'expired-callback': () => {
        cleanupRecaptcha();
      }
    });

    await recaptchaVerifier.render();
    return recaptchaVerifier;
  } catch (error) {
    console.error('Error initializing reCAPTCHA:', error);
    await cleanupRecaptcha();
    throw error;
  }
};

export const cleanupRecaptcha = async () => {
  if (recaptchaVerifier) {
    try {
      await recaptchaVerifier.clear();
    } catch (e) {
      console.warn('Error clearing reCAPTCHA:', e);
    } finally {
      recaptchaVerifier = null;
      const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
      iframes.forEach(iframe => iframe.remove());
    }
  }
}; 