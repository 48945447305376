import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import NotFound from '../NotFound';
import MessageBoard from '../pages/MessageBoard';
import { db } from '../config';

const EventMessageBoard = () => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const { urlSlug } = useParams();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        // First get the event ID from the urlSlug
        const urlIndexRef = doc(db, 'events/urlIndex', urlSlug);
        const urlIndexDoc = await getDoc(urlIndexRef);
        
        if (!urlIndexDoc.exists()) {
          setLoading(false);
          return;
        }

        // Then get the actual event data
        const eventRef = doc(db, 'events', urlIndexDoc.data().eventId);
        const eventDoc = await getDoc(eventRef);
        
        if (eventDoc.exists()) {
          setEvent({
            id: eventDoc.id,
            ...eventDoc.data()
          });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching event:', error);
        setLoading(false);
      }
    };

    fetchEvent();
  }, [urlSlug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!event) {
    return <NotFound />;
  }

  return <MessageBoard eventId={event.id} isEnabled={event.isEnabled} />;
};

export default EventMessageBoard;